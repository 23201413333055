<template>
  <RaiSetup
    :title="$t('backstock.setup.binLocations.title')"
    v-bind="$props"
    v-on="$listeners"
  >
    <template #extension>
      <p>
        {{ $t("backstock.setup.binLocations.extensionText[0]") }}
      </p>
      <p>
        {{ $t("backstock.setup.binLocations.extensionText[1]") }}
      </p>
    </template>
    <v-simple-table>
      <thead>
        <tr class="text-uppercase">
          <th class="text-left current-location-cell">
            {{ $t("backstock.setup.binLocations.currentLocationText") }}
          </th>
          <th class="forward-icon"></th>
          <th class="text-left next-location-cell">
            {{ $t("backstock.setup.binLocations.nextLocationText") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="location in locations" :key="location.name">
          <td>{{ location.name }}</td>
          <td class="text-left">
            <v-icon>$forward</v-icon>
          </td>
          <td>
            <div v-if="!location.next && !location.terminal">
              <VxSelect
                v-model="location.nextLocationId"
                :label="$t('backstock.setup.binLocations.nextLocationLabel')"
                name="nextLocationId"
                :items="locations"
                item-text="name"
                item-value="id"
                @change="nextLocationChanged(location, $event)"
              />
            </div>
            <div v-else :class="{ 'font-italic': location.terminal }">
              {{
                location.terminal
                  ? $t("backstock.setup.binLocations.terminalLocationText")
                  : location.next.name
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </RaiSetup>
</template>

<script>
import { RaiSetup, VxSelect } from "@/core-ui";

export default {
  name: "SpecifyBinLocations",
  components: {
    RaiSetup,
    VxSelect,
  },
  extends: RaiSetup,
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    nextLocationChanged(location, nextLocationId) {
      this.$emit("change-next-location", {
        currentLocation: { ...location },
        nextLocationId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .current-location-cell,
::v-deep .next-location-cell {
  // A big height for both cells, so that with 2 cells, current and next locations will take each 50% of remaining space
  width: 1000px;
}
</style>
