<template>
  <div>
    <VxConfirmDialog
      v-model="showDeleteTerminalDialog"
      :title="
        $t('backstock.setup.locations.deleteTerminalLocationInfoDialog.title')
      "
      hide-cancel
    >
      <p>
        {{
          $t(
            "backstock.setup.locations.deleteTerminalLocationInfoDialog.message[0]"
          )
        }}
      </p>
      <p>
        {{
          $t(
            "backstock.setup.locations.deleteTerminalLocationInfoDialog.message[1]"
          )
        }}
      </p>
      <p>
        {{
          $t(
            "backstock.setup.locations.deleteTerminalLocationInfoDialog.message[2]"
          )
        }}
      </p>
    </VxConfirmDialog>
    <VxConfirmDialog
      v-model="showDeleteWithBinsDialog"
      :title="
        $t('backstock.setup.locations.deleteLocationWithBinsInfoDialog.title')
      "
      hide-cancel
    >
      <p>
        {{
          $t(
            "backstock.setup.locations.deleteLocationWithBinsInfoDialog.message[0]"
          )
        }}
      </p>
      <p>
        {{
          $t(
            "backstock.setup.locations.deleteLocationWithBinsInfoDialog.message[1]"
          )
        }}
      </p>
    </VxConfirmDialog>
    <VxConfirmDialog
      v-model="showDeleteLocationConfirmDialog"
      :title="$t('backstock.setup.locations.deleteLocationConfirmDialog.title')"
      @ok="$emit('delete', locationToDelete)"
    >
      {{
        $t("backstock.setup.locations.deleteLocationConfirmDialog.message", {
          locationName: locationToDelete.name,
        })
      }}
    </VxConfirmDialog>
    <VxDataList
      :class="{ 'backstock-locations': true, dragging: isDragging }"
      :loading="loading"
      :headers="headers"
    >
      <template #groups>
        <!-- We need to specify tbody for animation -->
        <Draggable
          v-model="movableLocations"
          name="backstock-locations-tbody"
          tag="tbody"
          :move="onLocationDrag"
          @start="onLocationDragStart"
          @change="onLocationDragEnd"
        >
          <LocationListItem
            v-for="item in movableLocations"
            :key="item.id"
            class="backstock-locations-tbody-item"
            :item="item"
            :first-location="firstLocation"
            @edit="onEdit(item)"
            @delete="onDelete(item)"
          />
        </Draggable>
      </template>
    </VxDataList>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import Draggable from "vuedraggable";
import LocationListItem from "./LocationListItem.vue";
import { VxConfirmDialog, PaginationOptionsUtils, VxDataList } from "@/core-ui";

export default {
  name: "Locations",
  components: {
    VxDataList,
    LocationListItem,
    VxConfirmDialog,
    Draggable,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    locations: {
      type: Array,
      default: () => [],
    },
    firstLocation: {
      type: Object,
      default: undefined,
    },
  },
  data: (vm) => {
    const defaultPaginationOptions =
      PaginationOptionsUtils.buildDefaultPaginationOptions({
        itemsPerPage: 5,
        pageSizes: [5, 25, 50, 100],
      });
    return {
      isDragging: false,
      paginationOptions: defaultPaginationOptions,
      sortOptions: {},
      movableLocations: orderBy(vm.locations, (location) => location.sortOrder),
      locationToDelete: { name: "" },
      showDeleteTerminalDialog: false,
      showDeleteWithBinsDialog: false,
      showDeleteLocationConfirmDialog: false,
    };
  },
  computed: {
    headers() {
      return [
        { propertyPath: "sort_order", label: "", cssClass: "sorting" },
        {
          propertyPath: "name",
          label: this.$t("backstock.setup.locations.dataList.nameColumn"),
        },
        {
          propertyPath: "binCount",
          label: this.$t(
            "backstock.setup.locations.dataList.numberOfBinsColumn"
          ),
        },
        {
          propertyPath: "actions",
          label: this.$t("backstock.setup.locations.dataList.actionsColumn"),
          cssClass: "actions",
        },
      ];
    },
  },
  watch: {
    locations(newLocations) {
      this.movableLocations = orderBy(
        newLocations,
        (location) => location.sortOrder
      );
    },
  },
  methods: {
    onBack() {
      this.$emit("back");
    },
    onEdit(item) {
      this.$emit("edit", item);
    },
    onDelete(location) {
      if (location.terminal) {
        this.showDeleteTerminalDialog = true;
        return;
      }

      if (location.binCount > 0) {
        this.showDeleteWithBinsDialog = true;
        return;
      }

      // TODO - locationToDelete -> this can be done nicer, but we should change confirm dialog to open but with message override also.
      this.locationToDelete = location;
      this.showDeleteLocationConfirmDialog = true;
    },
    onLocationDrag(event) {
      // Just quick add and remove class, so that we can show and imediatelly fade yellow
      event.dragged.classList.add("dragged");
      event.related.classList.add("dragged");

      setTimeout(() => {
        event.dragged.classList.remove("dragged");
        event.related.classList.remove("dragged");
      }, 10);
    },
    onLocationDragStart() {
      this.isDragging = true;
    },
    onLocationDragEnd(event) {
      this.isDragging = false;

      if (event.moved.element) {
        const { element, newIndex } = event.moved;
        this.$emit("order-change", {
          location: { ...element },
          order: newIndex + 1,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.backstock-locations.dragging .backstock-locations-tbody-item {
  background-color: transparent !important;
  transition: background-color 0.7s ease-out;

  &.dragged {
    background-color: rgb(255, 255, 157) !important;
    transition: background-color 0s;
  }
}

.backstock-locations .actions {
  width: 120px;
}

.backstock-locations .sorting {
  width: 50px;
}

.backstock-locations .v-list-item {
  padding-left: 0;
}
</style>
