<template>
  <div>
    <Categories
      :categories="categories"
      :loading="!!loading"
      @back="onBack"
      @edit="onEdit"
      @delete="onDelete"
    />
    <router-view></router-view>
  </div>
</template>

<script>
import { CATEGORIES_QUERY, CATEGORY_DELETE } from "../graphql";
import Categories from "./Categories.vue";

export default {
  name: "CategoriesView",
  components: { Categories },
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    categories: [],
    loading: 0,
  }),
  apollo: {
    categories: {
      query: CATEGORIES_QUERY,
      loadingKey: "loading",
      variables() {
        return { storeId: this.storeId };
      },
      update(data) {
        return data.categories;
      },
    },
  },
  methods: {
    onBack() {
      this.$router.back();
    },
    onEdit(category) {
      this.$router.push({
        name: this.raiLinkTo.editCategory,
        params: { categoryId: category.id },
        preserveQuery: true,
      });
    },
    async onDelete(category) {
      const result = await this.$apollo.mutate({
        mutation: CATEGORY_DELETE,
        variables: { id: category.id },
      });

      // TODO - what with this ?
      // why for deletion we need to remove something and for update we do not ?
      this.mutationUpdate(this.$apollo.getClient().cache, result);
    },
    mutationUpdate(cache, result) {
      // Remove the category from the cache
      const category = result?.data?.deleteCategory?.category;
      const variables = { storeId: this.storeId };
      const { categories } =
        cache.readQuery({
          query: CATEGORIES_QUERY,
          variables,
        }) || [];

      const index = categories.findIndex((x) => x.id === category.id);

      // Return early if the category isn't found in the query
      if (index === -1) return;

      // Remove the category from the cache read result
      categories.splice(index, 1);

      // Write the categories back to the cache, excluding
      // the archived one
      cache.writeQuery({
        query: CATEGORIES_QUERY,
        variables,
        data: { categories },
      });
    },
  },
};
</script>
