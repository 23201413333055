<template>
  <VxDataListItem v-bind="$attrs" v-on="$listeners">
    <template #desktop>
      <td class="sorting">
        <v-icon>$menu</v-icon>
      </td>
      <td>
        {{ item.name }}
        <span
          v-if="item.id === firstLocation.id"
          class="grey--text font-italic ml-1"
        >
          {{ $t("backstock.setup.locations.dataListItem.defaultText") }}
        </span>
      </td>
      <td :class="{ 'font-italic': item.terminal }">
        {{ numberOfBinsParsed }}
      </td>
      <td>
        <!-- TODO: remove color="" after VxBtn fix -->
        <VxBtn color="" icon @click.stop="$emit('edit', item)">
          <v-icon>$edit</v-icon>
        </VxBtn>
        <VxBtn color="" icon @click.stop="$emit('delete', item)">
          <v-icon>$delete</v-icon>
        </VxBtn>
      </td>
    </template>
    <template #mobile>
      <v-list-item-icon>
        <v-icon>$menu</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ numberOfBinsParsed }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="item.id === firstLocation.id">
          {{ $t("backstock.setup.locations.dataListItem.defaultText") }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <VxBtn color="" icon @click.stop="$emit('edit', item)">
          <v-icon>$edit</v-icon>
        </VxBtn>
      </v-list-item-action>
      <v-list-item-action>
        <VxBtn color="" icon @click.stop="$emit('delete', item)">
          <v-icon>$delete</v-icon>
        </VxBtn>
      </v-list-item-action>
    </template>
  </VxDataListItem>
</template>

<script>
import { VxDataListItem, VxBtn } from "@/core-ui";

export default {
  name: "LocationListItem",
  components: { VxDataListItem, VxBtn },
  props: {
    item: {
      type: Object,
      required: true,
    },
    firstLocation: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    numberOfBinsParsed() {
      if (this.item.terminal) {
        return this.$t("backstock.setup.locations.dataListItem.terminalText");
      }

      return this.$tc(
        "backstock.setup.locations.dataListItem.numberOfBins",
        this.item.binCount
      );
    },
  },
};
</script>
