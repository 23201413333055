<template>
  <RaiSetups v-model="activeSetupQuery" :loading="loading">
    <SetUpBinNumbers
      :loading-creation="loadingBinCreation"
      :type="completedSetups[0]"
      :bins-status="binsStatus"
      :error-message="createBinsError"
      @view-all="onShowBins"
      @add-bins="onAddBins"
      @create-bins="onCreateBins"
      @next="onNext"
    />
    <ManageCategories
      v-bind="$props"
      :type="completedSetups[1]"
      :categories-count="categories.length"
      @add-category="onAddCategory"
      @next="onNext"
    />
    <ManageLocations
      v-bind="$props"
      :type="completedSetups[2]"
      :locations="orderedLocations"
      @add-location="onAddLocation"
      @next="onNext"
    />
    <SpecifyBinLocations
      :type="completedSetups[3]"
      :locations="orderedLocations"
      @change-next-location="onChangeNextLocation"
      @next="onNext"
    />
    <TrainTeam />
    <router-view></router-view>
  </RaiSetups>
</template>

<script>
import { RaiSetups, SetupType } from "@/core-ui";
import SetUpBinNumbers from "./components/SetUpBinNumbers.vue";
import ManageCategories from "./components/ManageCategories.vue";
import ManageLocations from "./components/ManageLocations.vue";
import SpecifyBinLocations from "./components/SpecifyBinLocations.vue";
import TrainTeam from "./components/TrainTeam.vue";

import {
  BIN_STATUS,
  BIN_BATCH_COMPLETED_SUB,
  BIN_BATCH_CREATE_INITIAL,
  CATEGORIES_QUERY,
  LOCATIONS_QUERY,
  LOCATION_UPDATE,
} from "./graphql";

import { find, get, sortBy } from "lodash";
import { useRouterQuery } from "@/mixins/routerQuery";

export default {
  name: "BackstockSetupView",
  components: {
    RaiSetups,
    SetUpBinNumbers,
    ManageCategories,
    ManageLocations,
    SpecifyBinLocations,
    TrainTeam,
  },
  mixins: [
    useRouterQuery({
      name: "activeSetup",
      initialValue: 0,
    }),
  ],
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    binsStatus: {
      query: BIN_STATUS,
      variables() {
        return {
          storeId: this.storeId,
        };
      },
      update(data) {
        return data.binStatus;
      },
    },
    categories: {
      query: CATEGORIES_QUERY,
      variables() {
        return {
          storeId: this.storeId,
        };
      },
      update(data) {
        return data.categories;
      },
    },
    locations: {
      query: LOCATIONS_QUERY,
      variables() {
        return {
          storeId: this.storeId,
        };
      },
      update(data) {
        const locations = data.locations;
        for (let locIndex = 0; locIndex < locations.length; locIndex++) {
          locations[locIndex].next = find(
            locations,
            (loc) => loc.id === locations[locIndex].nextLocationId
          );
        }

        return locations;
      },
    },
    $subscribe: {
      binBatchCompleted: {
        query: BIN_BATCH_COMPLETED_SUB,
        result() {
          this.$apollo.queries.binsStatus.refetch();
        },
      },
    },
  },
  data() {
    return {
      loading: false,
      loadingBinCreation: false,
      binsStatus: {},
      categories: [],
      locations: [],
      completedSetups: new Array(4),
      createBinsError: undefined,
    };
  },
  computed: {
    orderedLocations() {
      return sortBy(this.locations, (loc) => loc.sortOrder);
    },
  },
  methods: {
    onNext() {
      this.completedSetups[this.activeSetupQuery] = SetupType.COMPLETE;
      this.activeSetupQuery++;
    },
    onShowBins() {
      this.$router.push({
        name: this.raiLinkTo.showBins,
        preserveQuery: true,
      });
    },
    onAddBins() {
      this.$router.push({
        name: this.raiLinkTo.addBins,
        query: {
          startNumber: this.binsStatus.nextBin,
        },
        preserveQuery: true,
      });
    },
    onAddCategory() {
      this.$router.push({
        name: this.raiLinkTo.addCategory,
        preserveQuery: true,
      });
    },
    onAddLocation() {
      this.$router.push({
        name: this.raiLinkTo.addLocation,
        preserveQuery: true,
      });
    },
    async onCreateBins(data) {
      this.loadingBinCreation = true;
      this.createBinsError = undefined;

      try {
        await this.handleCreateBins(data);
      } catch (error) {
        this.createBinsError = error.toString();
        this.loadingBinCreation = false;
      }

      this.loadingBinCreation = false;
    },

    async onChangeNextLocation(data) {
      // TODO: resolve usage of this flags
      this.changeLocationLoading = true;
      this.changeLocationError = undefined;

      try {
        await this.handleUpdateNextLocation(data);
      } catch (error) {
        this.changeLocationLoading = false;
        this.changeLocationError = undefined;
      }

      this.changeLocationLoading = false;
    },

    async handleCreateBins({ prefix, quantity }) {
      const result = await this.$apollo.mutate({
        mutation: BIN_BATCH_CREATE_INITIAL,
        variables: {
          input: {
            prefix,
            quantity: parseInt(quantity, 10),
          },
        },
      });

      const errors = get(result, "data.createBinBatch.errors");

      if (errors.length) {
        throw Error(errors.join("; "));
      }

      return result;
    },

    async handleUpdateNextLocation({ currentLocation, nextLocationId }) {
      const result = await this.$apollo.mutate({
        mutation: LOCATION_UPDATE,
        variables: {
          input: {
            id: currentLocation.id,
            nextLocationId,
          },
        },
      });

      const errors = get(result, "data.updateLocation.errors");

      if (errors.length) {
        throw Error(errors.join("; "));
      }

      return result;
    },
  },
};
</script>
