<template>
  <VxDataListItem v-bind="$attrs" v-on="$listeners">
    <template #desktop>
      <td>
        {{ item.name }}
      </td>
      <td>{{ numberOfBinsParsed }}</td>
      <td>
        <!-- TODO: remove color="" after fixin VxBtn -->
        <VxBtn color="" icon @click.stop="$emit('edit', item)">
          <v-icon>$edit</v-icon>
        </VxBtn>
        <VxBtn color="" icon @click.stop="$emit('delete', item)">
          <v-icon>$delete</v-icon>
        </VxBtn>
      </td>
    </template>
    <template #mobile>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ numberOfBinsParsed }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <VxBtn color="" icon @click.stop="$emit('edit', item)">
          <v-icon>$edit</v-icon>
        </VxBtn>
      </v-list-item-action>
      <v-list-item-action>
        <VxBtn color="" icon @click.stop="$emit('delete', item)">
          <v-icon>$delete</v-icon>
        </VxBtn>
      </v-list-item-action>
    </template>
  </VxDataListItem>
</template>

<script>
import { VxDataListItem, VxBtn } from "@/core-ui";

export default {
  name: "CategoryListItem",
  components: { VxDataListItem, VxBtn },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    numberOfBinsParsed() {
      return this.$tc(
        "backstock.setup.categories.dataListItem.numberOfBins",
        this.item.backstocksCount
      );
    },
  },
};
</script>
