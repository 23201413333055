<template>
  <div>
    <Locations
      :locations="orderedLocations"
      :first-location="firstLocation"
      :loading="!!loading"
      @back="onBack"
      @edit="onEdit"
      @delete="onDelete"
      @order-change="onOrderChange"
    />
    <router-view></router-view>
  </div>
</template>

<script>
import { sortBy } from "lodash";
import Locations from "./Locations.vue";

import {
  LOCATIONS_QUERY,
  LOCATION_DELETE,
  LOCATION_REPOSITION,
} from "../graphql";

export default {
  name: "LocationsView",
  components: {
    Locations,
  },
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    locations: [],
    loading: 0,
  }),
  apollo: {
    locations: {
      query: LOCATIONS_QUERY,
      loadingKey: "loading",
      variables() {
        return {
          storeId: this.storeId,
        };
      },
      update(data) {
        return data.locations;
      },
    },
  },
  computed: {
    orderedLocations() {
      return sortBy(this.locations, (loc) => loc.sortOrder);
    },
    firstLocation() {
      if (!this.orderedLocations || this.orderedLocations.length <= 0) {
        return undefined;
      }

      return this.orderedLocations[0];
    },
  },
  methods: {
    onBack() {
      this.$router.back();
    },
    onEdit(location) {
      this.$router.push({
        name: this.raiLinkTo.editLocation,
        params: {
          locationId: location.id,
        },
        preserveQuery: true,
      });
    },
    async onOrderChange({ location, order }) {
      this.loading = true;

      await this.$apollo.mutate({
        mutation: LOCATION_REPOSITION,
        variables: {
          input: {
            id: location.id,
            position: order,
          },
        },
      });

      if (order < location.sortOrder) {
        for (let location of this.locations) {
          if (loc.id !== location.id && loc.sortOrder >= order) {
            loc.sortOrder = loc.sortOrder + 1;
          }
        }
      } else if (order > location.sortOrder) {
        for (let location of this.locations) {
          if (loc.id !== location.id && loc.sortOrder <= order) {
            loc.sortOrder = loc.sortOrder - 1;
          }
        }
      }

      const { data } = await this.$apollo.query({
        query: LOCATIONS_QUERY,
        fetchPolicy: "network-only",
        variables: {
          storeId: this.storeId,
        },
      });

      this.loading = false;

      this.$apollo.getClient().cache.writeQuery({
        query: LOCATIONS_QUERY,
        variables: { storeId: this.storeId },
        data: { locations: data.locations },
      });
    },

    async onDelete(location) {
      const result = await this.$apollo.mutate({
        mutation: LOCATION_DELETE,
        variables: { id: location.id },
      });
      // TODO - what with this ?
      // why for deletion we need to remove something and for update we do not ?
      this.mutationUpdate(this.$apollo.getClient().cache, result);
    },
    mutationUpdate(cache, result) {
      // Remove the location from the cache
      const location = result?.data?.deleteLocation?.location;
      const variables = { storeId: this.storeId };
      const { locations } =
        cache.readQuery({
          query: LOCATIONS_QUERY,
          variables,
        }) || [];

      const index = locations.findIndex((x) => x.id === location.id);

      // Return early if the location isn't found in the query
      if (index === -1) return;

      // Remove the location from the cache read result
      locations.splice(index, 1);

      // Write the locations back to the cache, excluding
      // the archived one
      cache.writeQuery({
        query: LOCATIONS_QUERY,
        variables,
        data: { locations },
      });
    },
  },
};
</script>
