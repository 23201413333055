<template>
  <TextWithLinksStep
    :title="$t('backstock.setup.trainTeam.title')"
    :sections="$t('backstock.setup.trainTeam.sections')"
  >
    <template #extension>
      {{ $t("backstock.setup.trainTeam.extensionText") }}
    </template>
  </TextWithLinksStep>
</template>

<script>
import TextWithLinksStep from "@/views/Common/TextWithLinksStep";

export default {
  name: "TrainTeam",
  components: {
    TextWithLinksStep,
  },
};
</script>
