<template>
  <div>
    <VxConfirmDialog
      v-model="showDeleteWithBinsDialog"
      :title="$t('backstock.setup.categories.deleteCategoryInfoDialog.title')"
      hide-cancel
    >
      <p>
        {{
          $t("backstock.setup.categories.deleteCategoryInfoDialog.message[0]")
        }}
      </p>
      <p>
        {{
          $t("backstock.setup.categories.deleteCategoryInfoDialog.message[1]")
        }}
      </p>
    </VxConfirmDialog>
    <VxConfirmDialog
      v-model="showDeleteCategoryConfirmDialog"
      :title="
        $t('backstock.setup.categories.deleteCategoryConfirmDialog.title')
      "
      @ok="$emit('delete', categoryToDelete)"
    >
      {{
        $t("backstock.setup.categories.deleteCategoryConfirmDialog.message", {
          categoryName: categoryToDelete.name,
        })
      }}
    </VxConfirmDialog>
    <VxDataList
      class="backstock-categories"
      :loading="loading"
      :headers="headers"
      :pagination-options="paginationOptions"
      :sort-options="sortOptions"
      @update:page="onPageUpdate"
      @update:itemsPerPage="onItemsPerPageUpdate"
      @update:sort="onSortUpdate"
      @showMore="onShowMore"
    >
      <template #body>
        <CategoryListItem
          v-for="item in categoriesPaginated"
          :key="item.id"
          :item="item"
          @edit="onEdit(item)"
          @delete="onDelete(item)"
        />
      </template>
    </VxDataList>
  </div>
</template>

<script>
import CategoryListItem from "./CategoryListItem.vue";
import {
  VxConfirmDialog,
  LocalPaginationUtils,
  PaginationOptionsUtils,
  VxDataList,
} from "@/core-ui";

export default {
  name: "Categories",
  components: { VxDataList, CategoryListItem, VxConfirmDialog },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data: (vm) => {
    const defaultPaginationOptions =
      PaginationOptionsUtils.buildDefaultPaginationOptions({
        itemsPerPage: 5,
        pageSizes: [5, 25, 50, 100],
      });

    const defaultSortOptions = PaginationOptionsUtils.buildDefaultSortOptions({
      sortBy: "name",
    });

    return {
      paginationOptions: defaultPaginationOptions,
      sortOptions: defaultSortOptions,
      categoryToDelete: { name: "" },
      showDeleteWithBinsDialog: false,
      showDeleteCategoryConfirmDialog: false,
    };
  },
  computed: {
    categoriesPaginated() {
      // NOT GOOD to do mutation of pagination options here, but the easiest way to simulate server side like behaviour
      LocalPaginationUtils.updateOptionsFromLocalData(
        this.paginationOptions,
        this.categories
      );
      return LocalPaginationUtils.paginateLocalItems(
        this.categories,
        this.paginationOptions,
        this.sortOptions,
        {
          ignoreCase: true,
        }
      );
    },
    headers() {
      return [
        {
          propertyPath: "name",
          label: this.$t("backstock.setup.categories.dataList.nameColumn"),
          sortable: true,
        },
        {
          propertyPath: "backstocksCount",
          label: this.$t(
            "backstock.setup.categories.dataList.numberOfBinsColumn"
          ),
          sortable: true,
        },
        {
          propertyPath: "actions",
          label: this.$t("backstock.setup.categories.dataList.actionsColumn"),
          cssClass: "actions",
        },
      ];
    },
  },
  methods: {
    onShowMore() {
      LocalPaginationUtils.fetchMoreData(this.paginationOptions);
    },
    onPageUpdate(page) {
      LocalPaginationUtils.updatePage(this.paginationOptions, page);
    },
    onItemsPerPageUpdate(itemsPerPage) {
      LocalPaginationUtils.updateItemsPerPage(
        this.paginationOptions,
        itemsPerPage
      );
    },
    onSortUpdate(header) {
      LocalPaginationUtils.updateSort(
        this.sortOptions,
        this.paginationOptions,
        header.propertyPath
      );
    },
    onBack() {
      this.$emit("back");
    },
    onEdit(category) {
      this.$emit("edit", category);
    },
    onDelete(category) {
      if (category.backstocksCount > 0) {
        this.showDeleteWithBinsDialog = true;
        return;
      }

      // TODO - categoryToDelete -> this can be done nicer, but we should change confirm dialog to open but with message override also.
      this.categoryToDelete = category;
      this.showDeleteCategoryConfirmDialog = true;
    },
  },
};
</script>

<style lang="scss">
.backstock-categories thead th.actions {
  width: 120px;
}
</style>
