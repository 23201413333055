<template>
  <!-- TODO: refactor to use RaiSetupForm -->
  <ValidationObserver v-slot="{ handleSubmit, invalid }" slim>
    <RaiSetup
      :title="$t('backstock.setup.bins.title')"
      :next-disabled="invalid || !hasActiveBinNumbers"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #extension>
        <i18n path="backstock.setup.bins.extensionText" tag="p">
          <template #learn-more>
            <a href="#">{{ $t("backstock.setup.bins.learnMoreText") }}</a>
          </template>
        </i18n>
      </template>
      <v-form @submit.prevent="handleSubmit(createBins)">
        <template v-if="hasActiveBinNumbers">
          <RaiSection
            :title="$t('backstock.setup.bins.activeBinNumbers.title')"
          >
            <p>
              {{
                $t("backstock.setup.bins.activeBinNumbers.description[0]", {
                  totalActiveBinNumbers,
                })
              }}
            </p>
            <p>
              {{
                $t("backstock.setup.bins.activeBinNumbers.description[1]", {
                  lastBinNumber,
                })
              }}
            </p>
          </RaiSection>
        </template>
        <template v-else>
          <RaiSection :title="$t('backstock.setup.bins.totalBins.title')">
            <p>
              {{ $t("backstock.setup.bins.totalBins.description") }}
            </p>
            <v-row>
              <v-col cols="12" md="2" xl="2">
                <VxTextField
                  v-model="form.quantity"
                  :label="$t('backstock.setup.bins.totalBins.fieldLabel')"
                  name="quantity"
                  :placeholder="
                    $t('backstock.setup.bins.totalBins.fieldPlaceholder')
                  "
                  type="number"
                  rules="required|min_value:1"
                />
              </v-col>
            </v-row>
          </RaiSection>
          <RaiSection :title="$t('backstock.setup.bins.binPrefix.title')">
            <p>
              {{ $t("backstock.setup.bins.binPrefix.description") }}
            </p>
            <v-row>
              <v-col cols="12" md="2" xl="2">
                <VxTextField
                  v-model="form.prefix"
                  :placeholder="
                    $t('backstock.setup.bins.binPrefix.fieldPlaceholder')
                  "
                  :label="$t('backstock.setup.bins.binPrefix.fieldLabel')"
                  name="prefix"
                  :suffix="$t('backstock.setup.bins.binPrefix.fieldSuffix')"
                  rules="required"
                />
              </v-col>
            </v-row>
          </RaiSection>
        </template>
        <VxAlert v-if="errorMessage" type="error">
          {{ errorMessage }}
        </VxAlert>
        <div class="d-flex justify-end mb-10">
          <template v-if="hasActiveBinNumbers">
            <VxBtn class="mr-2" secondary @click="viewAll">
              {{ $t("backstock.setup.bins.viewAllButtonText") }}
            </VxBtn>
            <VxBtn @click="addBins">
              {{ $t("backstock.setup.bins.addButtonText") }}
            </VxBtn>
          </template>
          <template v-else>
            <VxBtn type="submit" :loading="loadingCreation">
              {{ $t("backstock.setup.bins.createButtonText") }}
            </VxBtn>
          </template>
        </div>
        <VxAlert type="info">
          <i18n path="backstock.setup.bins.infoAlert">
            <template #note>
              <strong>{{ $t("backstock.setup.bins.noteText") }}</strong>
            </template>
            <template #here>
              <a href="#">{{ $t("backstock.setup.bins.hereText") }}</a>
            </template>
          </i18n>
        </VxAlert>
      </v-form>
    </RaiSetup>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { useFormObject } from "@/mixins/useFormObject";
import { VxTextField, VxAlert, VxBtn, RaiSetup, RaiSection } from "@/core-ui";

export default {
  name: "SetUpBinNumbers",
  components: {
    VxTextField,
    VxAlert,
    VxBtn,
    RaiSetup,
    RaiSection,
    ValidationObserver,
  },
  mixins: [
    useFormObject({
      prop: "bin",
      default: {
        quantity: 0,
        prefix: "",
      },
    }),
  ],
  props: {
    binsStatus: {
      type: Object,
      default: () => ({
        total: 0,
        lastBin: 0,
      }),
    },
    loadingCreation: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    hasActiveBinNumbers() {
      return this.totalActiveBinNumbers > 0;
    },
    totalActiveBinNumbers() {
      return this.binsStatus.total;
    },
    lastBinNumber() {
      return this.binsStatus.lastBin;
    },
  },
  methods: {
    createBins() {
      this.$emit("create-bins", { ...this.form });
    },
    addBins() {
      this.$emit("add-bins");
    },
    viewAll() {
      this.$emit("view-all");
    },
  },
};
</script>
