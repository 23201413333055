<template>
  <RaiSetup
    :title="$t('backstock.setup.categories.title')"
    v-bind="$attrs"
    :next-disabled="!hasActiveCategories"
    v-on="$listeners"
  >
    <template v-if="hasActiveCategories" #extension>
      <p>
        {{ $t("backstock.setup.categories.extensionText") }}
      </p>
    </template>
    <template v-if="hasActiveCategories">
      <CategoriesView v-bind="$props" />
    </template>
    <template v-else>
      <p class="font-weight-bold">
        {{ $t("backstock.setup.categories.description[0]") }}
      </p>
      <p>
        {{ $t("backstock.setup.categories.description[1]") }}
      </p>
    </template>
    <div class="d-flex justify-end mb-10">
      <VxBtn @click="addCategory">
        {{ $t("backstock.setup.categories.addButtonText") }}
      </VxBtn>
    </div>
    <VxAlert type="info" v-html="$t('backstock.setup.categories.infoAlert')" />
  </RaiSetup>
</template>

<script>
import { RaiSetup, VxAlert, VxBtn } from "@/core-ui";
import CategoriesView from "./CategoriesView.vue";

export default {
  name: "ManageCategories",
  components: {
    RaiSetup,
    VxAlert,
    VxBtn,
    CategoriesView,
  },
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    categoriesCount: {
      type: Number,
      default: 0,
    },
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasActiveCategories() {
      return this.categoriesCount > 0;
    },
  },
  methods: {
    addCategory() {
      this.$emit("add-category");
    },
  },
};
</script>
