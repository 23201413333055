<template>
  <RaiSetup
    :title="$t('backstock.setup.locations.title')"
    v-bind="$attrs"
    :next-disabled="!hasActiveLocations"
    v-on="$listeners"
  >
    <template #extension>
      <p>
        {{ $t("backstock.setup.locations.extensionText[0]") }}
      </p>
      <p>
        {{ $t("backstock.setup.locations.extensionText[1]") }}
      </p>
    </template>
    <template v-if="hasActiveLocations">
      <LocationsView v-bind="$props" />
    </template>
    <template v-else> </template>
    <div class="d-flex justify-end mb-10">
      <VxBtn @click="addLocation">
        {{ $t("backstock.setup.locations.addButtonText") }}
      </VxBtn>
    </div>
    <VxAlert type="info">
      <i18n path="backstock.setup.locations.infoAlert">
        <template #tip>
          <strong>{{ $t("backstock.setup.locations.tipText") }}</strong>
        </template>
        <template #learn-more>
          <a href="#">{{ $t("backstock.setup.locations.learnMoreText") }}</a>
        </template>
      </i18n>
    </VxAlert>
  </RaiSetup>
</template>

<script>
import { RaiSetup, VxAlert, VxBtn } from "@/core-ui";
import LocationsView from "./LocationsView";

export default {
  name: "ManageLocations",
  components: {
    RaiSetup,
    VxAlert,
    VxBtn,
    LocationsView,
  },
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    locations: {
      type: Array,
      default: () => [],
    },
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasActiveLocations() {
      return this.locations.length > 0;
    },
    totalActiveLocations() {
      return this.locations.length;
    },
  },
  methods: {
    addLocation() {
      this.$emit("add-location");
    },
  },
};
</script>
